<template>
    <div >
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-toolbar flat dense class="tc-title">
            <router-link to="student-internship-project" class="nav-arrow-left"><v-icon class="left-arrow" >mdi-arrow-left-bold</v-icon></router-link>
            <v-toolbar-title>Skill & Certification</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark class="logi mx-2" fab small @click="dialog_add=true"><v-icon>mdi-plus</v-icon></v-btn>
            <router-link to="student-experience" class="nav-arrow-right"><v-icon class="right-arrow">mdi-forward</v-icon></router-link>
        </v-toolbar>
        <!--<template>
            <v-toolbar flat dense color="white">
                <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details> </v-text-field>
                <v-spacer></v-spacer>
                <span style="width: 20px;"></span>
                <template>
                    <v-btn color="primary" dark class="mb-2" @click="dialog_add=true"><v-icon>mdi-plus</v-icon> New Record</v-btn>
                </template>
            </v-toolbar>
        </template>
        <template>
            <v-data-table :headers="headers" :items="Person_skill_lsit" class="elevation-1" :search="search">
                <template v-slot:item.docUrl="{ item }">
                    <span v-if="item.docUrl=='NA'">
                   {{ item.docUrl }}
                    </span>
                    <span v-else>
                       <a v-bind:href="item.docUrl" target="blank" class="alink"><v-icon color="success" >mdi-cloud-download</v-icon></a>
                    </span>
                </template>
                
                <template v-slot:item.edit="{ item }">
                    <v-icon color="indigo" @click="editItem(item)">mdi-pencil-circle</v-icon>
                </template>
                <template v-slot:item.delete="{ item }">
                   <v-icon color="red" @click="delId(item)">mdi-delete-circle</v-icon>
                </template>
             </v-data-table>
        </template>-->
        <v-row>
            <v-col cols="12" sm='6' v-for="item in Person_skill_lsit" :key="item">
                <v-card style=" margin: 8px; height:100%">
                    <v-card-title class="c-title">
                        {{item.skill}}
                    </v-card-title>
                    <v-card-text>
                        <v-row style="margin: 0px !important; padding: 0px !important">
                            <v-col cols="6" sm="4" style="margin: 0px !important; padding: 0px !important">
                                <span class="lbl">Skill Category</span>
                            </v-col>
                            <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important">
                                : {{item.skill_cat}}
                            </v-col>
                        </v-row>
                        <v-row style="margin: 0px !important; padding: 0px !important">
                            <v-col cols="6" sm="4" style="margin: 0px !important; padding: 0px !important">
                                <span class="lbl">Experience </span>
                            </v-col>
                            <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important">
                                :Year - {{item.expyears}} Months - {{item.expmonths}}
                            </v-col>
                        </v-row>
                        <v-row style="margin: 0px !important; padding: 0px !important">
                            <v-col cols="6" sm="4" style="margin: 0px !important; padding: 0px !important">
                                <span class="lbl">Certificate From</span>
                            </v-col>
                            <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important">
                                : {{item.certification_from }}
                            </v-col>
                        </v-row>
                        <v-row style="margin: 0px !important; padding: 0px !important">
                            <v-col cols="6" sm="4" style="margin: 0px !important; padding: 0px !important">
                                <span class="lbl">Description</span>
                            </v-col>
                            <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important">
                                : {{item.description}}
                            </v-col>
                        </v-row>
                        <v-row style="margin: 0px !important; padding: 0px !important">
                            <v-col cols="6" sm="4" style="margin: 0px !important; padding: 0px !important">
                                <span class="lbl">Certificate</span>
                            </v-col>
                            <v-col v-if="item.certification_link=='null'"  cols="6" md="6" style="margin: 0px !important; padding: 0px !important">
                                : NA
                            </v-col>
                            <v-col v-else cols="6" md="6" style="margin: 0px !important; padding: 0px !important">
                                : {{item.certification_link}}
                            </v-col>
                        </v-row>
                        <v-row style="margin: 0px !important; padding: 0px !important">
                            <v-col cols="6" sm="4" style="margin: 0px !important; padding: 0px !important">
                                <span class="lbl">Download</span>
                            </v-col>
                            <v-col cols="6" md="6" v-if="item.docUrl=='NA'" style="margin: 0px !important; padding: 0px !important">
                                : NA
                            </v-col>
                            <v-col cols="6" md="6" v-else style="margin: 0px !important; padding: 0px !important">
                                : <a v-bind:href="item.filepath" target="blank" class="alink"><v-icon color="success" >mdi-cloud-download</v-icon></a>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                                <v-icon color="indigo" @click="editItem(item)">mdi-pencil-circle</v-icon>
                                <v-spacer></v-spacer>
                                <v-icon color="red" @click="delId(item)">mdi-delete-circle</v-icon>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <template>
            <v-row justify="center">
                <v-dialog v-model="dialog_add" persistent max-width="700px">
                    <v-card>
                        <v-card-title class="card-title">
                             Add New Record 
                            
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-text-field placeholder="Enter Skill" label="Skill" v-model="savedata.skill" :rules="[v => !!v || 'required']"
                                            onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.keyCode == 44 || event.charCode == 32 "
                      :error-messages="error.savedata.skill ? 'Please enter skill' : ''"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-select :items="skill_cat_list" label="Skill Category" v-model="savedata.skill_cat" item-text="skill_cat" item-value="id" required :rules="[v => !!v || 'required']"
                                            :error-messages="error.savedata.skill_cat ? 'Please select skill category' : ''"></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="3">
                                        <v-select :items="year_list" label="Year" v-model="savedata.year" item-text="year" item-value="id" required 
                                            :error-messages="error.savedata.year ? 'Please select year' : ''"></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-select :items="month_list" label="Months" v-model="savedata.months" item-text="month" item-value="id" 
                                            :error-messages="error.savedata.months ? 'Please select months' : ''"></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-select :items="iscompleted_list" label="Is Certified " v-model="savedata.certifide" item-text="name" item-value="id" required :rules="[v => !!v || 'required']"
                                            :error-messages="error.savedata.certifide ? 'Please select certifide' : ''"></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-text-field placeholder="Enter Certification From" label="Certification From" v-model="savedata.from" :rules="[v => !!v || 'required']"
                                            onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.keyCode > 47 && event.keyCode < 58) || event.keyCode == 44 || event.charCode == 32 "
                      :error-messages="error.savedata.from ? 'Please enter Certification From' : ''"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field placeholder="Enter Certification Link" label="Certification Link" v-model="savedata.link" :rules="[v => !!v || 'required']"
                                            :error-messages="error.savedata.link ? 'Please enter Certification link' : ''"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-text-field placeholder="Enter Certification Description" label="Certification Description" v-model="savedata.description"
                                        :error-messages="error.savedata.description ? 'Please enter description' : ''"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-file-input type="file" placeholder="Select Certificate File" prepend-icon="mdi-file" label="Certificate File" ref="file" show-size v-model="savedata.file"
                                        :error-messages="error.savedata.file ? 'Please select Certificate file' : ''"></v-file-input>
                                        <font style="color: red; font-size:11px;">&emsp;&emsp;File Size Should be less than 250 kb,JPEG, JPG & PDF </font>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary darken-1"  outlined text @click="dialog_add= false">Cancel</v-btn>
                            <v-btn color="primary darken-1" dark @click="save()">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>
        <template>
            <v-row justify="center">
                <v-dialog v-model="dialog_delete" persistent max-width="290">
                    <v-card>
                        <v-card-title class="del-card-title">Record Delete</v-card-title>
                        <input type="hidden" v-model="deleteItem.skill_cert_id" />
                        <v-card-text>
                            Are you sure you want Delete this Record?
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1" text @click="dialog_delete = false">No</v-btn>
                            <v-btn color="red darken-1" text @click="deletedata()">Yes</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>
        <template>
            <div class="text-center">
                <v-dialog v-model="dialog_upload" hide-overlay persistent width="300">
                    <v-card color="primary" dark>
                        <v-card-text>
                            Please wait File uploding...
                            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </div>
        </template>
        <template>
            <v-row justify="center">
                <v-dialog v-model="dialog_edit" persistent max-width="700px">
                    <v-card>
                        <v-card-title class="card-title">
                            Edit Record
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-text-field placeholder="Enter Skill" label="Skill" v-model="edit_data.skill" :rules="[v => !!v || 'required']"
                                            onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.keyCode == 44 || event.charCode == 32 "
                      :error-messages="error.edit_data.skill ? 'Please enter skill' : ''"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-select :items="skill_cat_list" label="Skill Category" v-model="edit_data.skill_cat" item-text="skill_cat" item-value="id" required :rules="[v => !!v || 'required']"
                                            :error-messages="error.edit_data.skill_cat ? 'Please select skill category' : ''"></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="3">
                                        <v-select :items="year_list" label="Year" v-model="edit_data.year" item-text="year" item-value="id" required :rules="[v => !!v || 'required']"
                                            :error-messages="error.edit_data.year ? 'Please select year' : ''"></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-select :items="month_list" label="Months" v-model="edit_data.months" item-text="month" item-value="id" required :rules="[v => !!v || 'required']"
                                            :error-messages="error.edit_data.months ? 'Please select months' : ''"></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-select :items="iscompleted_list" label="Is Certifide " v-model="edit_data.certifide" item-text="name" item-value="id" required :rules="[v => !!v || 'required']"
                                            :error-messages="error.edit_data.certifide ? 'Please select certifide' : ''"></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-text-field placeholder="Enter Certification From" label="Certification From" v-model="edit_data.from" :rules="[v => !!v || 'required']"
                                            onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.keyCode > 47 && event.keyCode < 58) || event.keyCode == 44 || event.charCode == 32 "
                      :error-messages="error.edit_data.from ? 'Please enter Certification From' : ''"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field placeholder="Enter Certification Link" label="Certification Link" v-model="edit_data.link" :rules="[v => !!v || 'required']"
                                            :error-messages="error.edit_data.link ? 'Please enter Certification link' : ''"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-text-field placeholder="Enter Certification Description" label="Certification Description" v-model="edit_data.description" :rules="[v => !!v || 'required']"
                                            :error-messages="error.edit_data.description ? 'Please enter description' : ''"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-file-input type="file" placeholder="Select Certificate File" prepend-icon="mdi-file" label="Certificte File" ref="file" show-size v-model="edit_data.file" :rules="[v => !!v || 'required']"
                                            :error-messages="error.edit_data.file ? 'Please select Certificate file' : ''"></v-file-input>
                                            <span>Filename : &nbsp;</span>{{ file_name }}<br>
                                            <font style="color: red; font-size:11px;">&emsp;&emsp;File Size Should be less than 250 kb,JPEG, JPG & PDF </font>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialog_edit= false">Close</v-btn>
                            <v-btn color="blue darken-1" text @click="edit()">Update</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>
    </div>
</template>
<script>
    import axios from "axios";
    import StudentProfile from "./StudentProfile";
    export default {
        components: {
            StudentProfile,
        },
        data: () => ({
            dialog_upload: false,
            savedata: {
                file: null,
                description: null,
                skill: null,
                skill_cat: null,
                year: null,
                months: null,
                certifide: null,
                from: null,
                link: null,
                description: null,
            },
            edit_data: {
                file: null,
                description: null,
                skill: null,
                skill_cat: null,
                year: null,
                months: null,
                certifide: null,
                from: null,
                link: null,
                description: null,
                sk_id: "",
            },
            Person_skill_lsit: "",
            file_name: "",
           headers: [
                {
                    text: "Sr. No.",
                    align: "left",
                    sortable: false,
                    value: "srno",
                },
                {
                    text: "Skill",
                    align: "left",
                    sortable: false,
                    value: "skill",
                },
                {
                    text: "Skill Category",
                    align: "left",
                    sortable: false,
                    value: "skill_cat",
                },
                {
                    text: "Experience in Year",
                    align: "left",
                    sortable: false,
                    value: "expyears",
                },
                 {
                    text: "Experience in Month",
                    align: "left",
                    sortable: false,
                    value: "expmonths",
                },
                {
                    text: "Certificate From",
                    align: "left",
                    sortable: false,
                    value: "certification_from ",
                },
                {
                    text: "Description",
                    align: "left",
                    sortable: false,
                    value: "description",
                },
                 {
                    text: "Certificate",
                    align: "left",
                    sortable: false,
                    value: "certification_link",
                },
                {
                    text: "Download",
                    align: "left",
                    sortable: false,
                    value: "docUrl",
                },
               
                {
                    text: "Edit",
                    value: "edit",
                    sortable: false,
                },
                {
                    text: "Delete",
                    value: "delete",
                    sortable: false,
                },
            ],
            year_list: [
                { id: 0, year: "0" },
                { id: 1, year: "1" },
                { id: 2, year: "2" },
                { id: 3, year: "3" },
                { id: 4, year: "4" },
                { id: 5, year: "5" },
                { id: 6, year: "6" },
                { id: 7, year: "7" },
                { id: 8, year: "8" },
                { id: 9, year: "9" },
                { id: 10, year: "10" },
                { id: 11, year: "11" },
                { id: "12", year: "12" },
            ],
            month_list: [
                { id: 0, month: "0" },
                { id: 1, month: "1" },
                { id: 2, month: "2" },
                { id: 3, month: "3" },
                { id: 4, month: "4" },
                { id: 5, month: "5" },
                { id: 6, month: "6" },
                { id: 7, month: "7" },
                { id: 8, month: "8" },
                { id: 9, month: "9" },
                { id: 10, month: "10" },
                { id: 11, month: "11" },
                { id: 12, month: "12" },
            ],
            iscompleted_list: [
                { id: true, name: "Yes" },
                { id: false, name: "No" },
            ],

            error:{
                savedata:{
                    skill: false,
                    skill_cat: false,
                    year: false,
                    months: false,
                    certifide: false,
                    from: false,
                    link: false,
                    description: false,
                    file: false,
                },
                edit_data:{
                    skill: false,
                    skill_cat: false,
                    year: false,
                    months: false,
                    certifide: false,
                    from: false,
                    link: false,
                    description: false,
                    file: false,
                },
            },
            dialog_add: false,
            dialog_edit: false,
            snackbar_msg: "",
            color: "",
            snackbar: false,
            search: "",
            skill_cat_list: [],

            deleteItem: {
                skill_cert_id: "",
            },
            dialog_delete: false,
        }),

        watch: {
            dialog(val) {
                val || this.close();
            },
        },
        mounted() {
            this.onLoad();
        },
        methods: {
            onLoad() {
                axios
                    .post("/LearnerData/learnerSkillCertification")
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.skill_cat_list = res.data.skill_cat_List;
                            this.Person_skill_lsit = res.data.personSkill_List;
                        } else {
                            this.showSnackbar("#b71c1c", "No Data");
                           // window.console.log(res.data.msg);
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                       // window.console.log(error);
                    })
                    .finally(() => {
                        // 'use strict';
                    });
            },
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
            editItem(item) {
                //alert("ID "+item.id)
                this.dialog_edit = true;
                this.edit_data.skill = item.skill;
                this.edit_data.skill_cat = item.skill_cat_id;
                this.edit_data.year = item.expyears;
                this.edit_data.months = item.expmonths;
                this.edit_data.from = item.certification_from;
                this.edit_data.link = item.certification_link;
                this.edit_data.description = item.description;
                this.edit_data.sk_id = item.id;
                this.edit_data.certifide = item.iscompleted;
                this.file_name = item.filename;
                
            },

            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },
            hasWhiteSpace(s) {
                var status = false;
                var reWhiteSpace = new RegExp(/^\s+$/);

                // Check for white space
                if (reWhiteSpace.test(s)) {
                    // alert("Please Check Your Fields For Spaces");
                    //   return true;
                    return /^\s/.test(s);
                }
                //   return false;
                return /^\s/.test(s);
            },
            save() {
                if (this.savedata.skill == null) {
                    this.showSnackbar("#b71c1c", "Enter Skill...");
                    this.error.savedata.skill = true;
                    return;
                }else if( this.hasWhiteSpace(this.savedata.skill)){
                        this.showSnackbar("#b71c1c", "Enter valid skill...");
                        return;
                }
                 else if (this.savedata.skill_cat == null) {
                    this.showSnackbar("#b71c1c", "Select Skill Category..");
                    this.error.savedata.skill_cat = true;
                    return;
                } else if (this.savedata.year == null) {
                    this.showSnackbar("#b71c1c", "Select  Year..");
                    this.error.savedata.year = true;
                    return;
                } else if (this.savedata.months == null) {
                    this.showSnackbar("#b71c1c", "Select  Months..");
                    this.error.savedata.months = true;
                    return;
                }else if(this.savedata.certifide == null){
                    this.showSnackbar("#b71c1c", "select Is Certifide...");
                    this.error.savedata.certifide = true;
                    return;
                }
                 else if (this.savedata.from == null) {
                    this.showSnackbar("#b71c1c", "Enter Certification from...");
                    this.error.savedata.from = true;
                    return;
                }else if(this.hasWhiteSpace(this.savedata.from)){
                        this.showSnackbar("#b71c1c", "Enter valid Certification From...");
                        return;
                } else if(this.hasWhiteSpace(this.savedata.link)){
                        this.showSnackbar("#b71c1c", "Enter valid Certification link...");
                        return;
                } 
                else if (!this.savedata.description) {
                    this.showSnackbar("#b71c1c", "Enter Description...");
                    this.error.savedata.description = true;
                    return;
                }else if( this.hasWhiteSpace(this.savedata.description)){
                        this.showSnackbar("#b71c1c", "Enter valid Description...");
                        return;
                } 
                else if(this.savedata.file == null){
                    this.showSnackbar("#b71c1c", "Select Certificate file...");
                    this.error.savedata.file = true;
                    return;
                }
                 else {

                    //for only allow extensions while upload (e.g. .pdf  .jpeg  .png)
                    
                    var file = this.savedata.file.name;
                         var ext = file.split(".");
                            ext = ext[ext.length - 1].toLowerCase();
                            var arrayExtensions = ["pdf","jpeg","jpg"];

                            if (arrayExtensions.lastIndexOf(ext) == -1) {
                                // alert("Invalid file.. Only pdf can be uploaded");
                                this.showSnackbar("#b71c1c", "Please Select A only PDF, JPEG & JPG File...");
                            return
                            }
                    
                    //for only allow upload cv when size is 250kb
                    const upload = this.savedata.file;
                    const maxSize = 250 * 1024;
                    if(upload.size > maxSize){
                        this.showSnackbar("#b71c1c", "File size exceeds the limit (250kb)");
                        return
                    }
                    let formData = new FormData();
                    formData.append("skill", this.savedata.skill);
                    formData.append("skill_cat", this.savedata.skill_cat);
                    formData.append("year", this.savedata.year);
                    formData.append("months", this.savedata.months);
                    formData.append("certifide", this.savedata.certifide);
                    formData.append("link", this.savedata.link);
                    formData.append("from", this.savedata.from);
                    formData.append("description", this.savedata.description);
                    formData.append("file", this.savedata.file);

                    this.dialog_add = false;
                    this.dialog_upload = true;
                    axios
                        .post("/LearnerData/saveSkillCertification", formData, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        })
                        .then((res) => {
                            if (res.data.msg == "200") {
                                this.onLoad();
                                this.dialog_upload = false;
                                (this.savedata.description = null),
                                    (this.savedata.skill = null),
                                    (this.savedata.skill_cat = null),
                                    (this.savedata.year = null),
                                    (this.savedata.months = null),
                                    (this.savedata.certifide = null),
                                    (this.savedata.from = null),
                                    (this.savedata.link = null),
                                    (this.savedata.description = null),
                                    (this.savedata.file = null),
                                    this.showSnackbar("#4caf50", "Add Successfully..."); // show snackbar on success
                            } else {
                                this.dialog_upload = false;
                                this.showSnackbar("#b71c1c", " Already Present!!!");
                            }
                        })
                        .catch((error) => {
                           // window.console.log(error);
                        });

                    this.close();
                }
            }, // end of Save()
            edit() {
                if (!this.edit_data.skill) {
                    this.showSnackbar("#b71c1c", "Enter Skill...");
                    this.error.edit_data.skill = true;
                    return;
                }else if(this.hasWhiteSpace(this.edit_data.skill)){
                        this.showSnackbar("#b71c1c", "Enter valid skill...");
                        return;
                } 
                else if (this.edit_data.skill_cat == null) {
                    this.showSnackbar("#b71c1c", "Select Skill Category..");
                    this.error.edit_data.skill_cat = true;
                    return;
                } else if (this.edit_data.year == null) {
                    this.showSnackbar("#b71c1c", "Select  Year..");
                    this.error.edit_data.year = true;
                    return;
                } else if (this.edit_data.months == null) {
                    this.showSnackbar("#b71c1c", "Select  Months..");
                    this.error.edit_data.months = true;
                    return;
                }else if(this.edit_data.certifide == null){
                    this.showSnackbar("#b71c1c", "select Is Certifide...");
                    this.error.edit_data.certifide = true;
                    return;
                } 
                else if (!this.edit_data.from) {
                    this.showSnackbar("#b71c1c", "Enter Certification from...");
                    this.error.edit_data.from = true;
                    return;
                }else if(this.hasWhiteSpace(this.edit_data.from)){
                        this.showSnackbar("#b71c1c", "Enter valid Certification From...");
                        return;
                }else if(this.hasWhiteSpace(this.edit_data.link)){
                        this.showSnackbar("#b71c1c", "Enter valid Certification link...");
                        return;
                } 
                 else if (!this.edit_data.description) {
                    this.showSnackbar("#b71c1c", "Enter Description...");
                    this.error.edit_data.description = true;
                    return;
                }else if(this.hasWhiteSpace(this.edit_data.description)){
                        this.showSnackbar("#b71c1c", "Enter valid Description...");
                        return;
                }else if(this.edit_data.file == null){
                    this.showSnackbar("#b71c1c", "Select Certificate file...");
                    this.error.edit_data.file = true;
                    return;
                } 
                 else {
                    //for only allow extensions while upload (e.g. .pdf  .jpeg  .png)
                    
                    var file = this.edit_data.file.name;
                         var ext = file.split(".");
                            ext = ext[ext.length - 1].toLowerCase();
                            var arrayExtensions = ["pdf","jpeg","jpg"];

                            if (arrayExtensions.lastIndexOf(ext) == -1) {
                                // alert("Invalid file.. Only pdf can be uploaded");
                                this.showSnackbar("#b71c1c", "Please Select A only PDF, JPEG & JPG File...");
                            return
                            }
                    
                    //for only allow upload cv when size is 250kb
                    const upload = this.edit_data.file;
                    const maxSize = 250 * 1024;
                    if(upload.size > maxSize){
                        this.showSnackbar("#b71c1c", "File size exceeds the limit (250kb)");
                        return
                    }
                    let formData = new FormData();
                    formData.append("skill", this.edit_data.skill);
                    formData.append("skill_cat", this.edit_data.skill_cat);
                    formData.append("year", this.edit_data.year);
                    formData.append("months", this.edit_data.months);
                    formData.append("certifide", this.edit_data.certifide);
                    formData.append("link", this.edit_data.link);
                    formData.append("from", this.edit_data.from);
                    formData.append("description", this.edit_data.description);
                    formData.append("file", this.edit_data.file);
                    formData.append("cer_sk_id", this.edit_data.sk_id);
                    this.dialog_edit = false;
                    this.dialog_upload = true;
                    axios
                        .post("/LearnerData/editSkillCertification", formData, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        })
                        .then((res) => {
                            if (res.data.msg == "200") {
                                this.onLoad();
                                this.dialog_upload = false;

                                this.showSnackbar("#4caf50", "Update Successfully..."); // show snackbar on success
                            } else {
                                this.dialog_upload = false;
                                this.showSnackbar("#b71c1c", "Already Present!!!");
                            }
                        })
                        .catch((error) => {
                           // window.console.log(error);
                        });

                    this.close();
                }
            }, // end of Save()
            delId(item) {
                this.dialog_delete = true;
                this.deleteItem.skill_cert_id = item.id;
            },
            deletedata() {
                this.dialog_delete = false;

                axios.post("/LearnerData/deleteSkillCertification", this.deleteItem).then((res) => {
                    if (res.data.msg == "200") {
                        if ((this.load = true)) {
                            this.onLoad();
                            this.showSnackbar("#4caf50", " Record Delete Successfully...");
                            th;
                        }
                    } else {
                        this.showSnackbar("#b71c1c", "Record Not Delete...");
                    }
                });
            },
        },
    };
</script>
<style scoped>
.nav-arrow-left{
        margin-right: 5px;
        text-decoration: none;
        color: #FFF;
    }
    .nav-arrow-right{
        margin: 5px;
        text-decoration: none;
        color: rgb(3, 55, 167);
    }
    .left-arrow{
        color: #FFF;
    }
    .right-arrow{
        color: rgb(3, 55, 167);
    }
    .ec-title{
        background-image: linear-gradient(-100deg, #f4faba, #d64907);
        color: #FFF;
    }
    .c-title{
        background-image: linear-gradient(-90deg, #befcf7, #03948d);
        color: #FFF;
    }
    .tc-title{
        background-image: linear-gradient(-90deg, #FFF, #057996);
        color: #FFF;
        border-radius: 3px;
    }
    .logi{
        background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
        color: #FFF;
    }
    .card-title{
         background-image: linear-gradient(-90deg, #8edffd, #034c70);
        color: #FFF;
    }
    .del-card-title{
         background-image: linear-gradient(-90deg, #fde7e7, #ac0404);
        color: #FFF;
    }
    .lbl{
        color: #1705e6;
    }
    .v-text-field {
        padding-top: 0px;
        margin-top: 0px;
    }
    .alink {
        text-decoration: none;
    }

    .v-icon-size {
        font-size: 20px;
    }

    .edit-avatar {
        transition: 0.3s;
        border: 1px solid #b0bec5;
    }

    .edit-avatar:hover {
        background-color: #b0bec5;
    }

    .edit-v-icon {
        padding-left: 6px;
        font-size: 15px;
    }

    .edit-v-icon:hover {
        color: white;
    }
</style>
